import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';

import Image from 'common/components/Image';
import NewsletterImage from 'common/assets/image/syncStage/newsletter.svg';
import Text from 'common/components/Text';

function SuccessScreen({ content, details, rowStyle, titleStyle, textStyle }) {
  return (
    <Box {...rowStyle}>
      <Heading {...titleStyle}>
        <React.Fragment>
          <div style={{ margin: 'auto' }}>
            <Image src={NewsletterImage} alt="NewsLetter" style={{ display: 'inline' }} />
          </div>
          {content}
        </React.Fragment>
      </Heading>
      {details && <Text {...textStyle} content={details} />}
    </Box>
  );
}

SuccessScreen.propTypes = {
  row: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
  details: PropTypes.string,
};
SuccessScreen.defaultProps = {
  rowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: ['20px 40px', '30px 40px', '40px 80px', '60px 130px', '60px 200px'],
    width: ['200px', '300x', '300px', '400px', '500px'],
  },
  titleStyle: {
    fontSize: ['34px', '36x', '44px', '48px', '48px'],
    fontWeight: '600',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['30px', '50px', '50px', '50px', '50px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  textStyle: {
    color: '#fff',
    maxWidth: 980,
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '25px',
    lineHeight: '150%',
  },
  message: {
    fontSize: ['18px', '20px', '22px', '25px', '25px'],
    fontWeight: 'normal',
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.3',
    textAlign: 'center',
  },
};

export default SuccessScreen;
