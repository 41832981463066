export const categoriesOptionsData = [
  { value: 'gaming', label: 'Gaming' },
  { value: 'broadcast', label: 'Broadcast' },
  { value: 'live-performance', label: 'Live Performance' },
  { value: 'metaverse', label: 'Metaverse' },
  { value: 'education', label: 'Education' },
  { value: 'health-weallness', label: 'Health & Weallness' },
  { value: 'sport', label: 'Sport' },
  { value: 'events', label: 'Events' },
  { value: 'other', label: 'Other' },
];
