import { useState, useCallback } from 'react';
import axios from 'axios';

export const Status = {
  idle: 'IDLE',
  loading: 'LOADING',
  success: 'SUCCESS',
  error: 'ERROR',
};

export function useSubscribeToNewsletter() {
  const [status, setStatus] = useState(Status.idle);
  const [error, setError] = useState(null);

  const subscribe = useCallback(async (data) => {
    setError(null);
    setStatus(Status.loading);

    const axiosOptions = {
      headers: {},
    };

    try {
      await axios.post(
        `${process.env.SYNCSTAGE_API}/newsletter`,
        {
          ...data,
        },
        axiosOptions
      );
      setStatus(Status.success);
    } catch (err) {
      setStatus(Status.error);
      setError(err);
    }
  }, []);

  return { subscribe, status, error };
}
