import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  createTheme,
  FormHelperText,
  Stack,
  TextField,
  ThemeProvider,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { Link } from 'gatsby';
import { Wrapper } from './contact.style';
import Container from 'common/components/UI/Container';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import SuccessScreen from '../SuccessScreen';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendEmail } from './api';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Josefin Sans',
    allVariants: {
      color: 'white',
    },
  },
});

const defaultValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
  recaptchaToken: '',
  signUpForNewsletter: false,
  policyAccepted: false,
};

const Contact = ({ row, buttonWrapper, fillButton, title }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { handleSubmit, control, formState, setValue, watch } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  /**
   * @param {typeof defaultValues} data
   */
  const handleSendForm = async (data) => {
    setIsLoading(true);
    try {
      await sendEmail({
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        origin: 'syncstage',
        recaptchaToken: data.recaptchaToken,
        policyAccepted: data.policyAccepted,
        signUpForNewsletter: data.signUpForNewsletter,
      });
      setIsSuccess(true);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
    }
    setIsLoading(false);
  };

  if (isSuccess) {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Container className="form-box">
            <SuccessScreen content="Thank you for emailing us!" details="We will reach to you shortly." />
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }

  const captchaRequiredFields = ['name', 'email', 'subject', 'message'];
  const shouldShowCaptcha = captchaRequiredFields.every((field) => formState.dirtyFields[field]);
  const isRecaptchaValid = watch('recaptchaToken');

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Container className="form-box">
          <form onSubmit={handleSubmit(handleSendForm)}>
            <Box {...row}>
              <Box>
                <Heading {...title} content="Email us" />
                <Box className="pricing-box">
                  <Stack style={{ margin: 'auto' }} spacing={3} sx={{ width: [250, 250, 250, 300, 400] }}>
                    {isError && <Alert severity="error">Something went wrong. Please try again later.</Alert>}
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="name"
                          label="Name*"
                          placeholder="John Doe"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="email"
                          label="Email*"
                          placeholder="john@example.com"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="subject"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="subject"
                          label="Subject*"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="message"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="message"
                          label="Message*"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          multiline
                          rows={5}
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="policyAccepted"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl
                          required
                          error={fieldState.error?.message}
                          component="div"
                          style={{ marginTop: 20 }}
                          variant="outlined"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...field} name="policyAccepted" />}
                              label={
                                <>
                                  By submitting this form, I agree to the{' '}
                                  <Link style={{ color: 'white', textDecoration: 'underline' }} to="/policy">
                                    Privacy Policy.
                                  </Link>
                                </>
                              }
                            />
                          </FormGroup>
                          {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="signUpForNewsletter"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl
                          error={fieldState.error?.message}
                          component="div"
                          style={{ marginTop: 20 }}
                          variant="outlined"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...field} name="signUpForNewsletter" />}
                              label={
                                <>Sign up for our newsletter and get the latest news, events, and product updates.</>
                              }
                            />
                          </FormGroup>
                          {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    {(isRecaptchaValid || shouldShowCaptcha) && (
                      <div>
                        <ReCAPTCHA
                          sitekey={process.env.RECAPTCHA_SITE_KEY}
                          onChange={(token) => setValue('recaptchaToken', token, { shouldValidate: true })}
                        />
                        {formState.errors.recaptchaToken && (
                          <FormHelperText error>Please complete the captcha</FormHelperText>
                        )}
                      </div>
                    )}
                  </Stack>
                  <Box {...buttonWrapper}>
                    <Button {...fillButton} title="SUBMIT" type="submit" isLoading={isLoading} disabled={isLoading} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

Contact.propTypes = {
  row: PropTypes.object,
  title: PropTypes.object,
  buttonWrapper: PropTypes.object,
  fillButton: PropTypes.object,
};

Contact.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: ['20px 40px', '30px 40px', '40px 80px', '60px 130px', '60px 200px'],
  },
  title: {
    fontSize: ['24px', '26x', '28px', '30px', '30px'],
    fontWeight: 'normal',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['30px', '50px', '50px', '50px', '50px'],
    lineHeight: '1',
    textAlign: 'center',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
    loaderColor: '#fff',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: ['30px', '30px', '40px', '60px', '60px'],
  },
};

export default Contact;
