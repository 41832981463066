import axios from 'axios';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Button from 'common/components/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import Heading from 'common/components/Heading';
import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from 'common/components/UI/Container';
import { WhitepaperFormWrapper } from './whitepaperForm.style';
import {
  Autocomplete,
  Chip,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Box as MuiBox,
  FormHelperText,
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { categoriesOptionsData } from '../CategoriesDropdown';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import SuccessScreen from '../SuccessScreen';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Josefin Sans',
    allVariants: {
      color: 'white',
    },
  },
  zIndex: {
    modal: 99999,
  },
});

const defaultValues = {
  name: '',
  email: '',
  country: '',
  categories: [],
  role: '',
  recaptchaToken: '',
  policyAccepted: false,
  signUpForNewsletter: false,
};

const rolesData = [
  { value: 'manager', label: 'Manager' },
  { value: 'engineer', label: 'Engineer' },
  { value: 'developer', label: 'Developer' },
  { value: 'sales', label: 'Sales' },
  { value: 'entrepreneur', label: 'Entrepreneur' },
  { value: 'other', label: 'Other' },
];

const WhitepaperForm = ({ row, buttonWrapper, fillButton, title, whitepaperFile }) => {
  const Data = useStaticQuery(graphql`
    query {
      syncStageJson {
        COUNTRIES
        GAMES
        ROLES
      }
    }
  `);

  const { control, handleSubmit, setValue, formState, watch } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSendForm = async (data) => {
    const axiosOptions = {
      headers: {},
    };

    const { categories, ...rest } = data;
    try {
      axios.post(
        'https://api.sync-stage.com/whitepaper',
        {
          ...rest,
          category: categories.join(','),
          whitepaperFile, // 'OSM-5GFF-SyncStage%26EDS-WhitePaper.pdf', // 'OS_Discord_vs_SyncStage_White_Paper.pdf'
        },
        axiosOptions
      );
      setIsSuccess(true);
      setIsError(false);
    } catch (err) {
      setIsSuccess(false);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const captchaRequiredFields = ['name', 'email', 'country', 'categories', 'role'];
  const shouldShowCaptcha = captchaRequiredFields.every((field) => formState.dirtyFields[field]);
  const isRecaptchaValid = watch('recaptchaToken');

  if (isSuccess) {
    return (
      <ThemeProvider theme={theme}>
        <WhitepaperFormWrapper>
          <Container className="form-box">
            <SuccessScreen
              content="Thank you for signing up for the music Whitepaper"
              details="Check your email for the whitepaper download."
            />
          </Container>
        </WhitepaperFormWrapper>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <WhitepaperFormWrapper>
        <Container className="form-box">
          <form onSubmit={handleSubmit(handleSendForm)}>
            <Box {...row}>
              <div>
                <Heading
                  style={{ margin: '30px auto', marginTop: 0 }}
                  {...title}
                  content="Sign up to download the whitepaper"
                />
              </div>
              <Box>
                <Box className="pricing-box">
                  <Stack spacing={3} sx={{ width: [250, 250, 250, 300, 400] }}>
                    {isError && <Alert severity="error">Something went wrong. Please try again later.</Alert>}
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="name"
                          label="Name*"
                          placeholder="John Doe"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="email"
                          label="Email*"
                          placeholder="john@example.com"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      name="country"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          disablePortal
                          id="country"
                          options={Data.syncStageJson.COUNTRIES.sort()}
                          style={{ marginTop: 20 }}
                          fullWidth
                          onChange={(_, newValue) => {
                            field.onChange(newValue);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country*"
                              error={fieldState.error?.message}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="role"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          disablePortal
                          id="role"
                          options={rolesData}
                          style={{ marginTop: 20 }}
                          fullWidth
                          onChange={(_, newValue) => {
                            field.onChange(newValue?.value);
                          }}
                          getOptionLabel={(option) => option?.label ?? ''}
                          value={rolesData.find((role) => role.value === field.value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Role*"
                              error={fieldState.error?.message}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="categories"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel id="categories-label">Categories*</InputLabel>
                          <Select
                            labelId="categories-label"
                            id="category"
                            label="Category"
                            multiple
                            value={field.value}
                            onChange={field.onChange}
                            renderValue={(selected) => (
                              <MuiBox sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </MuiBox>
                            )}
                            error={fieldState.error?.message}
                            helperText={fieldState.error?.message}
                          >
                            {categoriesOptionsData.map((category) => (
                              <MenuItem key={category.value} value={category.value}>
                                {category.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="policyAccepted"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl
                          required
                          error={fieldState.error?.message}
                          component="div"
                          style={{ marginTop: 20 }}
                          variant="outlined"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...field} name="policyAccepted" />}
                              label={
                                <>
                                  By submitting this form, I agree to the{' '}
                                  <Link style={{ color: 'white', textDecoration: 'underline' }} to="/policy">
                                    Privacy Policy.
                                  </Link>
                                </>
                              }
                            />
                          </FormGroup>
                          {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="signUpForNewsletter"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl
                          error={fieldState.error?.message}
                          component="div"
                          style={{ marginTop: 20 }}
                          variant="outlined"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...field} name="signUpForNewsletter" />}
                              label={
                                <>Sign up for out newsletter and get the latest news, events, and product updates.</>
                              }
                            />
                          </FormGroup>
                          {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    {(isRecaptchaValid || shouldShowCaptcha) && (
                      <div>
                        <ReCAPTCHA
                          sitekey={process.env.RECAPTCHA_SITE_KEY}
                          onChange={(token) => setValue('recaptchaToken', token, { shouldValidate: true })}
                        />
                        {formState.errors.recaptcha && (
                          <FormHelperText error>Please complete the captcha</FormHelperText>
                        )}
                      </div>
                    )}
                  </Stack>
                  <Box {...buttonWrapper}>
                    <Button {...fillButton} title="SUBMIT" type="submit" isLoading={isLoading} disabled={isLoading} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Container>
      </WhitepaperFormWrapper>
    </ThemeProvider>
  );
};

WhitepaperForm.propTypes = {
  row: PropTypes.object,
  title: PropTypes.object,
  buttonWrapper: PropTypes.object,
  fillButton: PropTypes.object,
};

WhitepaperForm.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: ['20px 40px', '30px 40px', '40px 80px', '60px 130px', '60px 200px'],
  },
  title: {
    fontSize: ['24px', '26x', '28px', '30px', '30px'],
    fontWeight: 'normal',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['30px', '50px', '50px', '50px', '50px'],
    lineHeight: '1',
    textAlign: 'center',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
    loaderColor: '#fff',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: ['30px', '30px', '40px', '60px', '60px'],
  },
};

export default WhitepaperForm;
