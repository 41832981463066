import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('You must enter a name').max(100, 'Name is too long'),
  email: yup
    .string()
    .email('Invalid email provided')
    .required('You must enter an email')
    .max(100, 'Email is too long')
    .trim(),
  country: yup.string().required('You must enter a country'),
  categories: yup.array().min(1, 'You must select at least one category'),
  role: yup.string().required('You must enter a role'),
  recaptchaToken: yup.string().required('You must complete the captcha'),
  policyAccepted: yup.bool().oneOf([true], 'You must accept our privacy policy'),
  signUpForNewsletter: yup.bool(),
});
