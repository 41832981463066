import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  createTheme,
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  ThemeProvider,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'gatsby';
import { Wrapper } from './newsletter.style';
import Container from 'common/components/UI/Container';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import { Status, useSubscribeToNewsletter } from './useNewsletter';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import SuccessScreen from '../SuccessScreen';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Josefin Sans',
    allVariants: {
      color: 'white',
    },
  },
});

const defaultValues = {
  email: '',
  policyAccepted: false,
  recaptchaToken: '',
};

const Newsletter = ({ row, buttonWrapper, fillButton, title }) => {
  const { subscribe, status } = useSubscribeToNewsletter();

  const { handleSubmit, control, watch, formState, setValue } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const handleSendNewsletter = (data) => {
    subscribe({
      email: data.email,
      policyAccepted: data.policyAccepted,
      recaptchaToken: data.recaptchaToken,
      origin: 'SyncStage',
    });
  };

  if (status === Status.success) {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Container className="form-box">
            <SuccessScreen
              content={
                <>
                  Thank you for signing up for <span className="emphasize">SyncStage</span> Newsletter!
                </>
              }
            />
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }

  const captchaRequiredFields = ['email'];
  const shouldShowCaptcha = captchaRequiredFields.every((field) => formState.dirtyFields[field]);
  const isRecaptchaValid = watch('recaptchaToken');

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Container className="form-box">
          <form onSubmit={handleSubmit(handleSendNewsletter)}>
            <Box {...row}>
              <Box>
                <Heading {...title} content="Interested in all things SyncStage?" />
                <p style={{ color: 'white', maxWidth: 650, margin: 'auto', marginBottom: '30px' }}>
                  Get the latest news, events, and product updates from the SyncStage team.
                </p>
                <Box className="pricing-box">
                  <Stack style={{ margin: 'auto' }} spacing={3} sx={{ width: [250, 250, 250, 300, 400] }}>
                    {status === Status.error && (
                      <Alert severity="error">Something went wrong. Please try again later.</Alert>
                    )}
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          id="email"
                          label="Email"
                          variant="outlined"
                          style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}
                          fullWidth
                          error={fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="policyAccepted"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl
                          required
                          error={fieldState.error?.message}
                          component="div"
                          style={{ marginTop: 20 }}
                          variant="outlined"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...field} name="policyAccepted" />}
                              label={
                                <>
                                  By submitting this form, I agree to the{' '}
                                  <Link style={{ color: 'white', textDecoration: 'underline' }} to="/policy">
                                    Privacy Policy.
                                  </Link>
                                </>
                              }
                            />
                          </FormGroup>
                          {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    {(isRecaptchaValid || shouldShowCaptcha) && (
                      <div>
                        <ReCAPTCHA
                          sitekey={process.env.RECAPTCHA_SITE_KEY}
                          onChange={(token) => setValue('recaptchaToken', token, { shouldValidate: true })}
                        />
                        {formState.errors.recaptcha && (
                          <FormHelperText error>Please complete the captcha</FormHelperText>
                        )}
                      </div>
                    )}
                  </Stack>
                  <Box {...buttonWrapper}>
                    <Button
                      {...fillButton}
                      isLoading={status === Status.loading}
                      disabled={status === Status.loading}
                      title="SUBMIT"
                      type="submit"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

Newsletter.propTypes = {
  row: PropTypes.object,
  title: PropTypes.object,
  buttonWrapper: PropTypes.object,
  fillButton: PropTypes.object,
};

Newsletter.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: ['20px 40px', '30px 40px', '40px 80px', '60px 130px', '60px 200px'],
  },
  title: {
    fontSize: ['24px', '26x', '28px', '30px', '30px'],
    fontWeight: 'normal',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['30px', '50px', '50px', '50px', '50px'],
    lineHeight: '1',
    textAlign: 'center',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
    loaderColor: '#fff',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: ['30px', '30px', '40px', '60px', '60px'],
  },
};

export default Newsletter;
