import styled from 'styled-components';

export const WhitepaperFormWrapper = styled.section`
  .form-box {
    background: rgb(16 19 23 / 60%);
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
    padding: 0;
  }
`;
