import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email provided')
    .required('You must enter an email')
    .max(100, 'Email is too long')
    .trim(),
  policyAccepted: yup.bool().oneOf([true], 'You must accept our privacy policy'),
});
