import axios from 'axios';

/**
 * @param {Object} params
 * @param {string} params.name
 * @param {string} params.email
 * @param {string} params.subject
 * @param {string} params.message
 * @param {string} params.origin
 * @param {string} params.recaptchaToken
 * @param {boolean} params.policyAccepted
 * @param {boolean} params.signUpForNewsletter
 */
export function sendEmail(params) {
  return axios.post(`${process.env.SYNCSTAGE_API}/email-us`, params);
}
