import { openModal } from '@redq/reuse-modal';
import WhitepaperForm from '../../containers/SyncStage/whitepaperForm';
import Newsletter from '../../containers/SyncStage/Newsletter';
import Contact from '../../containers/SyncStage/Contact';

const handleWhitepaper5GFF = (e) => {
  openModal({
    config: {
      className: 'modal-content',
      disableDragging: true,
      width: '100%',
      height: '100%',
      animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
      animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
      transition: {
        mass: 1,
        tension: 180,
        friction: 26,
      },
    },
    component: WhitepaperForm,
    componentProps: {
      whitepaperFile: 'OSM-5GFF-SyncStage_EDS-WhitePaper.pdf',
    },

    closeOnClickOutside: false,
  });
  e.preventDefault();
};
const handleWhitepaperDiscord = (e) => {
  openModal({
    config: {
      className: 'modal-content',
      disableDragging: true,
      width: '100%',
      height: '100%',
      animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
      animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
      transition: {
        mass: 1,
        tension: 180,
        friction: 26,
      },
    },
    component: WhitepaperForm,
    componentProps: {
      whitepaperFile: 'OS_Discord_vs_SyncStage_White_Paper.pdf',
    },

    closeOnClickOutside: false,
  });
  e.preventDefault();
};
const handleNewsletter = () => {
  openModal({
    config: {
      className: 'modal-content',
      disableDragging: true,
      width: '100%',
      height: '100%',
      animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
      animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
      transition: {
        mass: 1,
        tension: 180,
        friction: 26,
      },
    },
    component: Newsletter,
    componentProps: {},

    closeOnClickOutside: false,
  });
};
const handleContact = () => {
  openModal({
    config: {
      className: 'modal-content',
      disableDragging: true,
      width: '100%',
      height: '100%',
      animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
      animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
      transition: {
        mass: 1,
        tension: 180,
        friction: 26,
      },
    },
    component: Contact,
    componentProps: {},
    closeOnClickOutside: false,
  });
};
const handleJoinCommunity = () => {
  const slackCommunityUrl = '';
  // 'https://join.slack.com/t/opensesamemedia/shared_invite/zt-162251zdd-OXcue6sakNB9iDeVbHOE6g';
  window.open(slackCommunityUrl, '_blank', 'noopener,noreferrer');
};
export { handleWhitepaper5GFF, handleWhitepaperDiscord, handleNewsletter, handleJoinCommunity, handleContact };
